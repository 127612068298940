import React from 'react'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Container } from "./styles"
import CDpoly from "../../assets/cdpolychip.png";
import Farben from "../../assets/farben.png";
import Logos from "../../assets/logos_polychip.png";
import Praesi from "../../assets/praesi.png";
import Produktflow from "../../assets/produktflow.png";
import DigitalTwin from "../../assets/digitaltwin_for_dark_bg.png";
import Theme from "../../assets/Umweltmonitoring_ohneBG.png";

import 'react-toastify/dist/ReactToastify.css'
import ScrollAnimation from "react-animate-on-scroll";
import ScrollToTop from "../ScrollToTop/ScrollToTop"

export function CDpolychip() {
    return (
        <div className="CDpolychip">
            <Header></Header>
            <Container>
                <ScrollToTop />
                <div className="wrapper">
                    <div className="header">
                        <div className="themeimage">
                        <ScrollAnimation animateIn="fadeInLeft" delay={0.20 * 1000}>

                            <img src={CDpoly} alt="Confioty prototyp" />
                            </ScrollAnimation>
                        </div>

                        <ScrollAnimation animateIn="fadeInRight" delay={0.30 * 1000}>
                            <div className="ueberblick">
                                <h2>Corporate Design polychip</h2>
                                <p>F&#252;r das Start-up polychip habe ich das Corporate Design entworfen, auf dessen Basis
                                    ich auch sp&auml;ter die Unternehmenswebsite entwickelt habe.
                                    Dabei lag der Fokus darauf, den technischen Bezug und frischen Charakter des jungen Unternehmens
                                    auch im Design abzubilden und zu betonen.</p>
                                <div className="abstand"><p> </p> </div>
                                <p><b>Projektlaufzeit:</b> 2 Monate</p>
                                <p><b>Tools: </b>Adobe Suite (InDesign, Illustrator, Photoshop)</p>
                            </div>
                        </ScrollAnimation>
                    </div>

                    <table>
                        <tbody>
                            <tr>
                                <td><h4>Kontext</h4></td>
                                <td>
                                    <p>
                                        Anf&auml;nglich brauchen die meisten Start-ups pl&ouml;tzlich auf einmal ganz viele Designs und wissen oft gar nicht,
                                        wo sie &#252;berhaupt damit beginnen sollen. Mit den Gr&#252;ndern von polychip habe ich in einer Vielzahl von
                                        Sessions &#252;ber ihre W&#252;nsche und Bed&#252;rfnisse gesprochen, angefangen von Farben und Schriften,
                                        hinzu einem Logo und einheitlichen Designelementen.
                                        Das Ziel war dabei einerseits den Kontext sinnvoll zu transportieren und den Charakter des neu wachsenden
                                        Unternehmens auszudr&#252;cken, andererseits aber auch eine einheitliche Bildsprache zu schaffen.
                                        Grafiken von polychip sollen als solche Wiedererkennungswert besitzen und die richtige Zielgruppe ansprechen.
                                   </p>
                                </td>
                            </tr>
                            <tr>
                                <td><h4>Einblicke</h4></td>
                                <td>
                                    <p><b>Farbauswahl</b></p>
                                    <p>
                                        Wir haben uns f&#252;r eine Farbpalette bestehend aus einem blaugr&#252;n, einem blau und einem lila Farbton in verschiedenen
                                        Helligkeitsstufen mit einem Schwarz und einem Wei&szlig; entschieden, wobei der Blaugr&#252;nton die Hauptfarbe ist.
                                        Damit soll einerseits die klassisch eher mit Technikfirmen
                                        assoziierten t&#252;rkis und blau T&ouml;ne wiedergefunden werden k&ouml;nnen, aber andererseits auch etwas Frische durch
                                        das zus&auml;tzliche Lila reingebracht werden.
                                    </p>
                                    <img className="farben" src={Farben} alt="polychip Farbpalette" />
                                    <p> </p>
 
                                    <p><b>Logogestaltung</b></p>
                                    <p>
                                        Das Logo stellt einen abstrahierten Halbleiter dar und ist somit eine Referenz auf den Namen des Start-up.
                                        Hier habe ich mit einer hellen und einer dunklen Variante f&#252;r verschiedene Hintergr&#252;nde  gearbeitet.
                                        Das Logo kommt mit Kurzvarianten f&#252;r Bereiche, in denen ein ganzes Logo nicht n&ouml;tig ist oder zu wenig Platz vorhanden ist.
                                        Au&szlig;erdem gibt es eine reine Schrift-Logovariante und ein Favicon.

                                    </p>
                                    <img className="logos" src={Logos} alt="Logo Varianten" />
                                    <p> </p>
                                    <p><b>Grafikdesign</b></p>
                                    <p>
                                        Neben der Auswahl und Gestaltung der Corporate Design Bestandteile habe ich auf Basis dieser eine Vielzahl an Grafiken
                                        f&#252;r Printmaterialien und digitale Werbematerialien (z.B. Flyer, Visitenkarten, Schaubilder, Icons usw.) und Pr&auml;sentationen
                                        erstellt.
                                    </p>
                                    <img className="ecken" src={Praesi} alt="Pr�sentationsvorlage" />
                                    <p><i>Unternehmenspr&auml;sentationsvorlage</i></p>
                                    <p> </p>
                                    <img className="ecken" src={Produktflow} alt="Produktflow Grafik" />
                                    <p><i>Websiteheader mit Produkticons</i></p>
                                    <p> </p>
                                    <img className="logos" src={DigitalTwin} alt="Grafik Digitaler Zwilling" />
                                    <p><i>Visualisierung f&#252;r den digitalen Zwilling</i></p>
                                    <p> </p>
                                    <img className="logos" src={Theme} alt="Logo Varianten" />
                                    <p><i>Themebild Umweltmonitoring</i></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>



                </div>
            </Container>
            <Footer></Footer>
        </div>
    )
}

