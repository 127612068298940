import React from 'react'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Container } from "./styles"
import Headerbild from "../../assets/Headerbild.png";
import Prozess from "../../assets/Prozess.png";
import Figma1 from "../../assets/figma1.png";
import Figma2 from "../../assets/figma2.png";
import Wireframe from "../../assets/wireframe.png";
import Userflow from "../../assets/userflow.png";
import Paperprototyp from "../../assets/paperprototyp.png";
import Whiteboardprototyp from "../../assets/whiteboardprototyp.png";
import 'react-toastify/dist/ReactToastify.css'
import ScrollAnimation from "react-animate-on-scroll";
import ScrollToTop from "../ScrollToTop/ScrollToTop"

export function Confioty() {
    return (
        <div className="Confioty">
            <Header></Header>
            <Container>
                <ScrollToTop />
                <div className="wrapper">
                    <div className="header">
                        <div className="themeimage">
                        <ScrollAnimation animateIn="fadeInLeft" delay={0.20 * 1000}>

                            <img src={Headerbild} alt="Confioty prototyp" />
                            </ScrollAnimation>
                        </div> 

                        <ScrollAnimation animateIn="fadeInRight" delay={0.30 * 1000}>
                            <div className="ueberblick">
                                <h2>Confioty IoT-Konfigurator</h2>
                            <p>Im Rahmen meiner Abschlussarbeit habe ich ein Konzept und einen Prototyp f&#252;r
                                einen Konfigurator entworfen. Dieser dient dazu, verschiedene Komponenten eines IoT-Projektes
                                zu erfassen und die ben&#246;tigte Hard- und Software zu konfigurieren.</p>
                            <div className="abstand"><p> </p> </div>
                            <p><b>Dauer:</b> 3 Monate</p>
                            <p><b>Tools: </b>Figma, Miro, Paper Sketching</p>
                            <p><b>Methoden: </b>Interviews, User Flows, KANO-Analyse, Wireframing, Prototyping</p>
                            </div>
                        </ScrollAnimation>
                    </div>

                    <table>
                        <tbody>
                            <tr>
                                <td data-label="Inhalt"><h4>Kontext</h4></td>
                                <td data-label="Inhalt"><p>
                                    Bei der Planung von komplexen IoT-Projekten ist es selbst f&#252;r Branchenprofis nicht immer einfach, den &Uuml;berblick
                                    &#252;ber alle beteiligten und ben&ouml;tigten Komponenten, ihre Anschl&#252;sse und ihre weiteren Besonderheiten zu behalten.
                                    Projekte k&ouml;nnen schnell eine gro&szlig;e Komplexit&auml;t besitzen oder r&auml;umliche Gegebenheiten k&ouml;nnen die Planung erschweren.
                                    Um bei der Projektplanung Zeit und Aufwand zu sparen, auch Nutzer*innen mit geringerem Domainwissen der Einstieg zu erleichtern
                                    und die Soft- und Hardwarebestandteile realit&auml;tsnah abbilden zu k&ouml;nnen, ist es daher wichtig, ein geeignetes Konfigurationstool daf&#252;r zu haben.
                                </p>
                                    {/* 
                                    <p>
                                    Im Rahmen des Confioty-Projekts entsteht aktuell bei dem Startup polychip der Industrial IoT-Konfigurator, der L&ouml;sungen wie Zustands&#252;berwachungen, Predictive Maintenance
                                    oder Digitale Zwillinge von Anlagen aus Produktion, Energieerzeugung und Prozessindustrie konfigurierbar machen soll.
                                    Ich habe im Rahmen meiner Abschlussarbeit ein Frontend f&#252;r den Konfigurator geplant, entworfen und gestaltet.
                                    Das Ziel war dabei die, in der Konfigurationsdatenbank vorhandenen Einschr&auml;nkungen und Bedingungen in eine verst&auml;ndliche optische Darstellung zu bringen.
                                    Somit soll bei der Planung von IoT-Projekten Zeit und Aufwand gespart werden und auch Nutzer*innen mit geringerem Domainwissen der Einstieg erleichtert werden.
                                    </p>
                                    */ }
                                </td>
                            </tr>
                            <tr>
                                <td data-label="Inhalt"><h4>Prozess</h4></td>
                                <td data-label="Inhalt">
                                    <img id="prozess" src={Prozess} alt="Prozess Beschreibung" />
                                </td>
                            </tr>
                            <tr>
                                <td data-label="Inhalt"><h4>Einblicke</h4></td>
                                <td data-label="Inhalt">
                                    <p><b>Research (Discovery Phase)</b></p>
                                    <p>
                                        Ich habe zu Beginn Studien zu Konfigurationstools untersucht und einen Kriterienkatalog aufgestellt,
                                        wie Gestaltungsrichtlinien und Good Practice f&#252;r Konfiguratoren aussehen kann und welche konkreten Handlungsanweisungen
                                        sich dadurch f&#252;r den Entwurf ableiten lassen.
                                        Au&szlig;erdem habe ich eine Konkurrenzrecherche gemacht. Dabei wurde schnell deutlich, dass die Handhabung dieser
                                        Konfiguratoren auf dem Markt meist sehr komplex und teilweise sogar schwierig war.
                                        Als Zielsetzung wurde daher ein einfach verst&auml;ndliches 2D-Interface gesetzt, dass auch ohne detailliertes
                                        technisches Know-how bedienbar ist.
                                    </p>

                                    <p><b>Problemdefinition (Define Phase)</b></p>
                                    <p>
                                        Zun&auml;chst habe ich eine Ist-Analyse gemacht, um einen &Uuml;berblick &#252;ber schon vorhandenen Software (wie z.B. die Konfigurationsdatenbank) zu erhalten.
                                        Um die potenziellen Nutzer*innen besser zu verstehen, habe ich Stakeholder-Interviews durchgef&#252;hrt und
                                        zus&auml;tzliche User Research betrieben. Die Ziele der Anwendung habe ich mithilfe des Aufstellens von verschiedenen Use Cases 
                                        spezifiziert. Aus diesen gesammelten Erkenntnissen ist dann eine umfassende Anforderungsanalyse nach der KANO-Methode entstanden.
                                    </p>

                                    <p><b>Ideen Generierung (Ideate Phase)</b></p>
                                    <p>
                                        In diesem Entwicklungsabschnitt habe ich eng mit meinem Betreuerteam bei polychip gearbeitet.
                                        In gemeinsamen Brainstorming-Sessions haben wir
                                        erst auf Papier, dann auf einem Whiteboard (um die interaktive Natur des Konfigurators besser abbilden zu k&ouml;nnen)
                                        die Informationsarchitektur geplant.
                                        
                                    </p>

                                    <img id="prozess" src={Paperprototyp} alt="Prozess Beschreibung" />

                                    <img id="prozess" src={Whiteboardprototyp} alt="Prozess Beschreibung" />

                                    <p>
                                        Erg&auml;nzend dazu habe ich User Flows entwickelt, um verschiedene Nutzungsszenarien aufzuzeigen und fr&#252;hzeitig
                                        potenziellen Probleme und Abbruchsstellen identifizieren zu k&ouml;nnen.
                                    </p>

                                    <img id="prozess" src={Userflow} alt="Prozess Beschreibung" />

                                    <p><b>Prototyp Gestaltung (Design Phase)</b></p>
                                    <p>
                                        In der Design Phase war es endlich Zeit, alle gesammelten Erkenntnisse und Ideen in einem Prototyp zusammenzubringen.
                                        Zuerst habe ich daf&uuml;r in Miro ein Wireframe des Konfigurators erstellt. Dabei lag der Fokus vor allem darauf,
                                        die User Flows zu visualisieren und die Hauptfunktionalit&auml;ten und Prozesse abzubilden. Diese Wireframes waren
                                        somit eine Low-Fidelity-Stufe des Prototyps, da sie sich leicht &uuml;berarbieten und anpassen lie&szlig;en und noch
                                        kein festgelegtes Design besa&szlig;en.
                                    </p>

                                    <img id="prozess" src={Wireframe} alt="Prozess Beschreibung" />

                                    <p>
                                        Zuletzt ging es daran, einen High-Fidelity-Prototyp in Figma zu kreieren. Dabei habe ich die Gestaltung an das 
                                        Corporate Design von polychip angepasst.
                                        F&uuml;r diesen Prototyp habe ich einerseits das Frontend horizontal dargestellt, also unter Ber&uuml;cksichtigung aller
                                        ben&ouml;tigten Screens des Konfigurationsprozesses, Ansichten und Men&uuml;s. Au&szlig;erdem habe ich besonders wichtige
                                        Funktionalit&auml;ten noch einmal mit einer vertikalen Ansicht verdeutlicht, um ihre Funktionsweise f&uuml;r das
                                        Entwicklungsteam klar und schrittweise darzustellen.
                                    </p>

                                    <img id="prozess" src={Figma1} alt="Prozess Beschreibung" />

                                    <img id="prozess" src={Figma2} alt="Prozess Beschreibung" />

                                </td>
                            </tr>
                            <tr>
                                <td data-label="Inhalt"><h4>Abschluss</h4></td>
                                <td data-label="Inhalt">
                                    <p>
                                        Die gr&ouml;&szlig;te Herausforderung des Projektes war, die Balance zu finden zwischen einfacher Benutzbarkeit und
                                        technischer Vollst&auml;ndigkeit. Um diese zu erreichen, haben wir festgestellt, dass es sinnvoll ist,
                                        mehr und mehr mit Default-Eingaben und Templates zu arbeiten.
                                        Somit konnte gew&auml;hrleistet werden, dass erfahrene Nutzer*innen
                                        trotzdem Werte &auml;ndern k&ouml;nnen, um ihre Projekte genau auf ihre Probleme zuzuschneiden.
                                        Weniger erfahrene Nutzer*innen wird aber gleichzeitig die H&uuml;rde genommen sich in alle diese Probleme und Fragestellungen
                                        einarbeiten zu m&uuml;ssen, um das Tool erfolgreich nutzen zu k&ouml;nnen.
                                    </p>
                                    <p> 
                                        Im Zeitrahmen meiner Abschlussarbeit war leider keine Zeit f&uuml;r ein ausgiebiges Testing. Im Prozess
                                        w&auml;re sonst der n&auml;chste Arbeitsschritt ein Usability Testing des Prototyp mit Nuter*innen gewesen,
                                        gefolgt von einer Feedbackschlaufe und einer Reevaluation des Prototyp.
                                    </p>
                                    <p>
                                        
                                        Aktuell befindet sich der IoT-Konfigurator in der Developmentphase. Aktuelle Informationen und
                                        Entwicklungsst&auml;nde sind unter <a href="https://software.polychip.ai/products/confioty/" target="_blank" rel="noreferrer">https://software.polychip.ai/products/confioty/</a> zu finden.

                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>



                </div>
            </Container>
            <Footer></Footer>
        </div>
    )
}

