import React from 'react'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Container } from "./styles"
import 'react-toastify/dist/ReactToastify.css'
import ScrollToTop from "../ScrollToTop/ScrollToTop"

export function Impressum() {
    return (
        <div className="Impressum">
            <Header></Header>
            <Container>
                <ScrollToTop />
                <div id="wrapper">
                    <h1>Impressum</h1>
                    <p>Diese Seite hat keinen kommerziellen Nutzen und dient nur als pers&ouml;nliches Portfolio.</p>

                    <h3>Die folgenden Angaben entsprechen den Vorgaben von &sect;5 TMG:</h3>

                    <p>Sarah Mielke</p>

                    <h3>Kontaktdaten:</h3>
                    <p><a href="mailto:mielke.sarah@outlook.de">mielke.sarah@outlook.de</a></p>


                    <h3>Haftung f&uuml;r Inhalte:</h3>
                    <p>Im Sinne von &sect;7 Absatz 1 TMG sind wir f&uuml;r die eigenen Inhalte auf dieser Webseite verantwortlich.
                        Durch &sect;&sect;8 bis einschlie&szlig;lich 10 TMG sind wir aber nicht verpflichtet, gespeicherte oder
                        &uuml;bermittelte fremde Inhalte zu &uuml;berwachen oder diese auf Rechtswidrigkeit zu pr&uuml;fen.
                        Das befreit uns jedoch nicht von der Pflicht, der Sperrung und Entfernung von Informationen nach
                        geltenden Gesetzen nachzukommen.
                    </p>
                </div>
            </Container>
            <Footer></Footer>
        </div>
    )
}

