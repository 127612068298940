import { createGlobalStyle } from "styled-components";
import BrightDEMO2 from "./font/BrightDEMO2.woff2";
import BrightDEMO from "./font/BrightDEMO.woff";




export const GlobalStyle = createGlobalStyle`

  :root{
    --beige: #B2A399; //khaki
    --black: #171312; //not fully black! smoky black
    --white: #F5F5F5; // white smoke
    --silver: #C8BBB6; 
    --darkbrown1: #473B36;
    --darkbrown2: #2D2522;
    --platinum: #E9E4E2;
    scroll-padding-top: 10rem;

    &.light{

      body{
        transition: 0.5s;
        background-color: var(--platinum); 
        color: var(--black);
      }

      .logo{
        color: var(--black);
      }

      a {
          color: var(--black);
      :hover {
      color: #574842;
      }
    }

      h1, h4 {
           color: var(--darkbrown1);
           text-shadow: var(--silver) 1px 1px 50px;
          
      }

      td:nth-of-type(1) {
           text-decoration-color: var(--darkbrown1);
      }

      h2, h3 {
          color: var(--darkbrown2);
          text-shadow: var(--silver) 1px 1px 50px;
      }

      header.header-fixed{
        transition: 0.5s;
        background-color: #f5f5f550;
        a{
          transition: 0.5s;
          color: black;
        }
        .menu,.menu:before, .menu:after{
          background-color: var(--black); 
        }
        .menu.active{
          background-color: rgba(555,555,555,0);
        }
      }

      footer.footer{
        transition: 0.5s;
        background-color: var(--silver);
        color: var(--black);
      }

  .project{
      background-color: var(--silver);
      :hover{
          background-color: var(--beige);
      }
      h3 {
          color:var(--black);
          :hover{
            color: var(--darkbrown1);
            border-bottom: 1px solid var(--darkbrown1);
            }
            }

       svg {
           fill: var(--darkbrown1);
            }
}

    }
  }



  ul, li {
    text-decoration: none;
    padding: 0.25rem;
    padding-left: 1.25rem;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 62.5%;
  }

  body{
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: var(--white);

  }

  p, body, input, textarea, button, ul, li {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.1rem;
    font-size: calc(12.5px + 0.390625vw);
  }

  h1, h2 {
    font-family: 'Bright DEMO', serif;
  }

  h1 {
      color:var(--white);
      font-size: calc(25px + 0.390625vw);
  }

  h2 {
      color:var(--beige);
      font-size: calc(40px + 0.390625vw);
  }

  h3 {
    font-size: calc(18px + 0.390625vw);
  }

  h4 {
       color:var(--beige);
      font-size: calc(16px + 0.390625vw);
  }

  td:nth-of-type(1) {
      text-decoration-color: var(--beige);
    }

  a{
    text-decoration: none;
    color: var(--silver);
  :hover {
      color: #938279;
    }
  }

  button, .button{
    border: none;
    cursor: pointer;
    background-color: var(--beige);
    color: #FFFF;
    border-radius: 2rem;
    font-weight: 500;
    transition: filter 0.25s;
    &:hover{
      filter: brightness(0.8);
    }
  }

  button:disabled, .button:disabled{
    filter: brightness(0.8);
    cursor: not-allowed;
  }

  .project{
      background-color: #231D1B;
      :hover{
          background-color: var(--darkbrown2);
      }
      h3 {
          color:var(--white);
          :hover{
            color: var(--silver);
            border-bottom: 1px solid var(--silver);
            }
            }
      svg {
          fill: #c8bbb6;
      }
}

@media (min-width: 1600px) {
     p, body, input, textarea, button, ul, li {
        font-size: calc(11px + 0.390625vw);
        }

@media (max-width: 700px) {
     p, body, input, textarea, button, ul, li {
        font-size: calc(13px + 0.390625vw);
        }
  
      @font-face {
    font-family: 'Bright DEMO';
    src: url(${BrightDEMO2}) format('woff2'),
       url(${BrightDEMO}) format('woff');
    }
`