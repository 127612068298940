import styled from "styled-components";

export const Container = styled.section`
  .wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33em, 1fr));
  gap: 1.5rem;
  padding-top: 10%;
  }

  .about-text {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .hard-skills{
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .hability{
    display: flex;
    flex-direction: column;
  }

  h2{
    display: inline-block;
    margin-bottom: 1.5rem;
    margin-top: 0rem;
  }

  summary {
    cursor: pointer;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
}
/* Style für Webkit-Browser */
summary::-webkit-details-marker {

}
summary::before {
   padding-right: 1rem;
}
details[open] summary::before {
   padding-right: 1rem;
}

  h3{
    margin-top: 2.5rem;
    margin-bottom: 0rem;
  }

  p{
    letter-spacing: 0.1rem;
    font-weight: 500;
  }
  

  .about-image{
    text-align: right;
   img{
     margin-top: 2rem;
     width: 80%;
   }
  }

@media (min-width: 1600px){
   .wrapper {
         width: 80%;
         margin: 0 auto;
         padding-top: 15%;
         }
   }

@media (max-width: 1400px){
    .about-image{
      img{
        margin-top: 6rem;
        width: 90%;
        }
    }


@media (max-width: 1200px){
    .wrapper {
        width: 100%;
        grid-template-columns: repeat(auto, minmax(12em, 1fr));
    }
    .about-text {
        flex: 2;
    }
    .hard-skills{
        margin-top: 2.5rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .about-image{
        max-width: 100%;
        text-align: center;
        img{
            margin-top: 6rem;
            width: 50%;
            }
    }
  }

@media (max-width: 480px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
    .about-image {
        width: 100%;
        margin-top: 4rem;
        text-align: center;
        img{
            margin-top: 2rem;
            width: 100%;
    }
  }
`