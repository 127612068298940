import styled from "styled-components";


export const Container = styled.footer`
  background-color: #231D1B;
  padding: 2rem 10rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  h3 {
        font-size: calc(13px + 0.390625vw);
    }

  p{
    letter-spacing: 0.1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 0.5rem;
    img{
      width: 2.6rem;
      animation: spinning 5s infinite linear;
    }
  }

 #break {
    flex-direction: column;
    text-align: left;
  }

  @keyframes spinning {
    0%{
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  @media(max-width: 800px){
    padding: 4rem 10rem;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
  @media(max-width: 600px){
    padding: 4rem 1rem;
    }

    @media(max-width: 550px){
    }

    @media (min-width: 1600px){
         padding: 3rem 10%;
         }
  }
`