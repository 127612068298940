import { Container } from "./styles";
import { HashLink } from 'react-router-hash-link'
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
    return (
        <Container id="project">
            <div className="wrapper">
                <h2>Projekte</h2>
                <div className="projects">

                    <ScrollAnimation animateIn="flipInX">
                        <div className="project">
                            <header>
                                <div className="body">
                                    <HashLink to="/confioty"> <h3>Confioty IoT-Konfigurator</h3> </HashLink>
                                </div>
                                <div className="project-links">
                                    <HashLink to="/confioty">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg>
                                    </HashLink>
                                </div>
                            </header>
                            <div className="body">
                                <h4>Konzeption & Prototyping</h4>
                                <p>In meinem Abschlussarbeitsprojekt habe ich &#252;ber drei Monate in Zusammenarbeit mit dem Start-up polychip ein Konzept und einen Prototyp f&#252;r ein Konfigurationstool entworfen. Das Tool dient dazu, verschiedene Komponenten eines IoT-Projektes zu erfassen und die ben&#246;tigte Hard- und Software zu konfigurieren.</p>
                            </div>
                            <footer>
                                <ul className="tech-list">
                                    <li>UX</li>
                                    <li>Wireframe</li>
                                    <li>Figma</li>
                                    <li>Miro</li>
                                </ul>
                            </footer>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="flipInX">
                        <div className="project">
                            <header>
                                <div className="body">
                                    <a href="https://polychip.ai/" target="_blank" rel="noreferrer"> <h3>Website polychip</h3> </a>
                                </div>
                                <div className="project-links">
                                    <a href="https://polychip.ai/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg>
                                    </a>
                                </div>
                            </header>
                            <div className="body">
                                <h4>Design & Umsetzung</h4>
                                <p>F&#252;r das Start-up polychip habe ich in enger Zusammenarbeit mit den Gr&#252;ndern eine Unternehmenswebsite konzipiert, gestaltet und umgesetzt.
                                    Die mehrseitige Website beinhaltet unter anderem eine Landingpage, ein Kontaktformular, informative Seiten zu Team, Kompetenzen etc. und ist responsiv gestaltet.
                                </p>
                            </div>
                            <footer>
                                <ul className="tech-list">
                                    <li>WordPress</li>
                                    <li>React</li>
                                    <li>CSS</li>
                                    <li>HTML</li>
                                </ul>
                            </footer>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="flipInX">
                        <div className="project">
                            <header>
                                <div className="body">
                                    <a href="https://bildungsportal.sachsen.de/opal/auth/RepositoryEntry/10928291840/CourseNode/1666751784023900006" target="_blank" rel="noreferrer"> <h3>Online-Kurse HTW Dresden</h3> </a>
                                </div>
                                <div className="project-links">
                                    <a href="https://bildungsportal.sachsen.de/opal/auth/RepositoryEntry/10928291840/CourseNode/1666751784023900006" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg>
                                    </a>
                                </div>
                            </header>
                            <div className="body">
                                <h4>Gestaltung von E-Learning Angeboten</h4>
                                <p>Als wissenschaftliche Hilfskraft im Team Lehre der HTW Dresden habe ich Online-Kurse und Selbstlernmateriealien
                                    zu den Themen <a href="https://bildungsportal.sachsen.de/opal/auth/RepositoryEntry/10928291840/CourseNode/1666751784023900006" target="_blank" rel="noreferrer">Lehre gestalten</a>,
                                    <a href="https://bildungsportal.sachsen.de/opal/auth/RepositoryEntry/22591045634/CourseNode/101109484419258?12" target="_blank" rel="noreferrer">Lehrvideos</a>
                                    und <a href="https://bildungsportal.sachsen.de/opal/auth/RepositoryEntry/10928291840/CourseNode/1673580762860572009?10" target="_blank" rel="noreferrer">H5P</a>
                                    technisch und didaktisch konzeptioniert, gestaltet und umgesetzt. Der Fokus lag dabei besonders auf der Barrierearmut der bereitgestellten Angebote.</p>
                            </div>
                            <footer>
                                <ul className="tech-list">
                                    <li>OPAL</li>
                                    <li>CSS</li>
                                    <li>HTML</li>
                                    <li>JS</li>

                                </ul>
                            </footer>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="flipInX">
                        <div className="project">
                            <header>
                                <div className="body">
                                    <HashLink to="/cdpolychip"> <h3>Corporate Design polychip</h3> </HashLink>
                                </div>
                                <div className="project-links">
                                    <HashLink to="/cdpolychip">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg>
                                    </HashLink>
                                </div>
                            </header>
                            <div className="body">
                                <h4>Mediengestaltung digital & Print</h4>
                                <p>F&#252;r das Start-up polychip habe ich ein Corporate Design bestehend aus dem Logo,
                                    den Hauptschriften und einem Farbkonzept entwickelt. Auf der Bildsprache des CD aufbauend,
                                    habe ich verschiedene Medien gestaltet, wie informative Grafiken, Visitenkarten, Werbematerialien und Pr&#228;sentationen.</p>
                            </div>
                            <footer>
                                <ul className="tech-list">
                                    <li>InDesign </li>
                                    <li>Illustrator  </li>
                                    <li>Photoshop </li>
                                </ul>
                            </footer>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="flipInX">
                        <div className="project">
                            <header>
                                <div className="body">
                                    <a href="https://minor-pole.de/" target="_blank" rel="noreferrer"> <h3>Vereinswebsite Minor</h3> </a>
                                </div>
                                <div className="project-links">
                                    <a href="https://minor-pole.de/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg>
                                    </a>
                                </div>
                            </header>
                            <div className="body">
                                <h4>Umgestaltung & Mitbearbeitung</h4>
                                <p>Im Rahmen meines Ehrenamtes bei Minor e.V. habe ich die Umgestaltung der Vereinswebsite im Jahr 2021
                                    im Team mit einer weiteren Entwicklerin realisiert. Dar&#252;ber hinaus trage ich gelegentlich zu
                                    Aktualisierung der Vereinswebsite und zu den Inhalten der Social Media Pr&#228;senz bei.</p>
                            </div>
                            <footer>
                                <ul className="tech-list">
                                    <li>CMS </li>
                                    <li>WordPress</li>
                                    <li>Photoshop</li>
                                </ul>
                            </footer>
                        </div>
                    </ScrollAnimation>



                    { /* 
                  <ScrollAnimation animateIn="flipInX">
                      <div className="project">
                          <header>
                              <div className="body">
                                  <a href="https://kameleon.co.in/" target="_blank" rel="noreferrer"> <h3>Titel</h3> </a>
                              </div>
                              <div className="project-links">
                                  <a href="https://kameleon.co.in/" target="_blank" rel="noreferrer">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="#c8bbb6" stroke="#c8bbb6" stroke-width="" stroke-linecap="round" stroke-linejoin="round" ><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg>
                                  </a>
                              </div>
                          </header>
                          <div className="body">
                              <h4>Untertitel</h4>
                              <p>
                                  Beschreibung              </p>
                          </div>
                          <footer>
                              <ul className="tech-list">
                                  <li>Technologie</li>
                              </ul>
                          </footer>
                      </div>
                  </ScrollAnimation>
                  */ }

                </div></div>
        </Container>
    );
}