import styled from "styled-components";


export const Container = styled.main`
  position: relative;
  z-index: 0;
  padding: 0 2rem;
  overflow-x: hidden;

#wrapper {
    width: 100%;
    min-height: 100vh;
    padding: 0% 10%;
}

h1, h3, p {
    margin: 1%;
}

h1 {
    padding-bottom: 3rem;
    padding-top: 20rem;
    font-size: 4rem;
}

h3 {
    padding-bottom: 1rem;
    padding-top: 4rem;
}
 @media only screen and (max-width: 700px) {
     h1 {
         padding-top: 10rem;
        }
     }
`