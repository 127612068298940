import React from 'react'
import { Homepage } from './components/Homepage/Homepage'
import { Impressum } from './components/Impressum/Impressum'
import { Hero } from './components/Hero/Hero'
import { About } from './components/About/About'
import { Project } from './components/Project/Project'
import { CDpolychip } from './components/CDpolychip/CDpolychip'
import { Confioty } from './components/Confioty/Confioty'
import { GlobalStyle } from './styles/global'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
          <GlobalStyle></GlobalStyle>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Homepage />} />
              </Routes>
              <Routes>
                  <Route path="/home#top" element={<Hero />} />
              </Routes>
              <Routes>
                  <Route path="/home#about" element={<About />} />
              </Routes>
              <Routes>
                  <Route path="/home#project" element={<Project />} />
              </Routes>
              <Routes>
                  <Route path="/home" element={<Homepage />} />
              </Routes>

              <Routes>
                  <Route path="/impressum" element={<Impressum />} />
              </Routes>
              <Routes>
                  <Route path="/cdpolychip" element={<CDpolychip />} />
              </Routes>
              <Routes>
                  <Route path="/confioty" element={<Confioty />} />
              </Routes>
          </BrowserRouter>
    </>
  )
}

export default App
