import { Container } from "./styles";
import Profilbild from "../../assets/Profilbild.png";
import ScrollAnimation from "react-animate-on-scroll";


export function About() {
    return (
        <Container id="about">
            <div className="wrapper">
                <div className="about-text">
                    <ScrollAnimation animateIn="fadeInLeft">
                        <h2>About</h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
                        <p>
                            Hallo, ich bin Sarah. Ich bin immer auf der Suche nach dem Schnittpunkt von optimaler Benutzbarkeit und raffiniertem Design.
                            Dabei bin ich &uuml;berzeugt, dass eine Herangehensweise, die von den menschlichen Bed&uuml;rfnissen ausgeht, der Schl&uuml;ssel f&uuml;r intuitive und ansprechende digitale Produkte ist.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "1.5rem" }}>
                        <p>
                            Ich konzeptioniere am liebsten neue User Interfaces, gestalte Prototypen oder entwickle Frontend.
                            Projekte setzte ich mit einer strukturierten Herangehensweise, sinnvoll priorisierten To-do-Listen und ganz viel Kommunikation um. Dabei treffe ich die Balance zwischen Detailverliebtheit und &Uuml;berblick.
                        </p>
                    </ScrollAnimation>

                    <div className="hard-skills">
                        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
                            <details open>
                                <summary>
                                    Meine Skills
                                </summary>

                                <ul>
                                    <li> Prototyping & Wireframing (Figma, Responsive Design)</li>
                                    <li>UX & Usability (Informations Architektur, User Research, Barrierefreiheit)</li>
                                    <li>Frontend-Entwicklung (HTML, CSS, Javascript, React)</li>
                                    <li>Content Management Systeme (WordPress, OPAL)</li>
                                    <li>Layout, Gestaltung & Bildbearbeitung (InDesign, Illustrator, Photoshop)</li>
                                    <li>3D-Modellierung & Animationen (Blender, Maya)</li>
                                    {/*<li>Videoschnitt (Adobe Premiere Pro)</li>*/}
                                    <li>Bildredaktion (Recherche, Lizenzen, Urheberrecht)</li>
                                </ul>
                            </details>
                        </ScrollAnimation>
                    </div>

                </div>
                <div className="about-image">
                    <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
                        <img src={Profilbild} alt="Sarah Mielke" />
                    </ScrollAnimation>
                </div>
            </div>
        </Container>
    )
}
