import { Container } from "./styles"
import { Hero } from "../Hero/Hero"
import { About } from "../About/About";
import { Project } from "../Project/Project";
import { ReactComponent as Bg } from "../../assets/BG.svg";

export function Main() {
    return (

        <Container>
            <Bg />
            <div id="wrapper">
                <Hero></Hero>
                <About></About>
                <Project></Project>
            </div>
        </Container>
    );
}
