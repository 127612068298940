import styled from "styled-components";

export const Container = styled.section`
  margin-top: 15%;
  margin-bottom: 15%;
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  background: rgba(0,0,0,0);

 .abstand {
        height: calc(100px + 1.3vw);
    }

  .hero-text{
      padding-top: 5%;
    & > p{
      letter-spacing: 0.1rem;
    }
    h1{
      font-size: calc(90px + 0.390625vw);
    }

    h3{
      margin: 0.4rem 0;
    }

    h4 {
        font-weight: 500;
    }

  }


    @media(max-width: 1400px){
    display: block;
    margin-top: 35%;
  }

  @media(max-width: 1050px){
    display: block;
    margin-top: 10%;
    margin-bottom: 10%;
    .abstand {
        height: 18rem;
    }
  }

 @media(max-width: 900px){
    padding-top: 10%;
  }

  @media(max-width: 800px){
    margin-top: 15%;
  }

  @media(max-width: 500px){
    margin-top: 15%;
    margin-bottom: 30%;
    .hero-text {
    h1 {
        font-size: 5.5rem;
    }
    }
    
    .abstand {
        height: 10rem;
    }
  }

  @media (min-width: 1600px){
   width: 80%;
   margin: 0 auto;
   .abstand {
        height: 50rem;
    }
  }
`