import { Container } from './styles'
import { HashLink } from 'react-router-hash-link'
import reactIcon from '../../assets/react-icon.svg'


export function Footer() {
    return (
        <Container className="footer">


            <div id="break">
                <p>&#169; Sarah Mielke 2024</p>
            </div>

            <div>
                <p>
                    <h3>Thank you for scrolling!</h3> <img src={reactIcon} alt="React" />
                </p>
            </div>


            <HashLink smooth to="/impressum" className="Homepage">
                <p>Impressum</p>
            </HashLink>
        </Container>
    )
}
