import { Container } from './styles'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import { useState } from 'react'

export function Header() {
    const [isActive, setActive] = useState(false)

    function toggleTheme() {
        let html = document.getElementsByTagName('html')[0]
        html.classList.toggle('light')
    }

    function closeMenu() {
        setActive(false)
    }

    return (
        <Container className="header-fixed">
            <HashLink smooth to="/home#top" className="logo">
                <h1>Sarah Mielke</h1>
            </HashLink>

            <input
                onChange={toggleTheme}
                className="container_toggle"
                type="checkbox"
                id="switch"
                name="mode"
            />
            <label htmlFor="switch">Toggle</label>

            <nav className={isActive ? 'active' : ''}>
                <p><NavHashLink smooth to="/home#top" onClick={closeMenu}>
                    Home
                </NavHashLink></p>

                <NavHashLink smooth to="/home#about" onClick={closeMenu}>
                    About
                </NavHashLink>

                <NavHashLink smooth to="/home#project" onClick={closeMenu}>
                    Projekte
                </NavHashLink>
            </nav>

            <div
                aria-expanded={isActive ? 'true' : 'false'}
                aria-haspopup="true"
                aria-label={isActive ? 'Fechar menu' : 'Abrir menu'}
                className={isActive ? 'menu active' : 'menu'}
                onClick={() => {
                    setActive(!isActive)
                }}
            ></div>
        </Container>
    )
}
