import styled from "styled-components";


export const Container = styled.section`
  margin-top: 15rem;
  
  h2{
    text-align: left;
    margin-bottom: 3rem;
  }

  ul, li {
      list-style-type: none;
      margin: 0.75rem;
      padding: 0;
  }

  .projects{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem 0rem;

    .project{
      padding: 2rem 1.8rem;
      border-radius: 1.2rem;
      transition: 0.25s;
      display: flex;
      flex-direction: column;
      height: 100%;
      &:hover{
        transform: translateY(-5px);
      }

      .body {
            margin: 1rem;
            h4 {
                font-size :1.8rem;
                margin-bottom: 2rem;
                }
        }

      header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 2rem;
        .project-links{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 2rem;
          a:hover {
            underline: none;
          }

          svg {
              width: 3rem;
          }
        }
      }

      p{
        letter-spacing: 0.1rem;
        margin-bottom: 1rem;
        }

       h3 {
          transition: color 0.25s;
          }

      footer{
        margin-top: auto;
        .tech-list{
          max-width: 31rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.8rem;
          opacity: 0.6;
        }
      }

    }
  }

 @media (max-width: 1350px){
    .projects{
      grid-template-columns: repeat(2, minmax(18rem, 1fr));
    }

    .project {
        padding: 1rem;
        }
    }
  }



  @media (max-width: 740px){
    .projects{
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
      padding: 0;
    
    .project {
        padding: 1rem;
        gap: 0.5rem;
        }
    }
  }
  }

        @media (min-width: 1600px){
        .wrapper {
         width: 80%;
         margin: 0 auto;
         }
   }
`