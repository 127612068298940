import React from 'react'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Main } from '../Main/Main'
import 'react-toastify/dist/ReactToastify.css'
export function Homepage() {
    return (
        <div className="Homepage">
            <Header></Header>
            <Main></Main>
            <Footer></Footer>
        </div>
    )
}

