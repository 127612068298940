import styled from "styled-components";


export const Container = styled.main`
  position: relative;
  z-index: 0;
  padding: 0 10rem;
  overflow-x: hidden;

#wrapper {
    width: 100%;
}

#bg {
    position: absolute;
    z-index: -1;
    left: calc(-200px + 1.3vw);
    top: calc(-650px + 1.3vw);
    width: 115%;
    height: 100%;
}

#lines {
  transform-origin: 820px 850px;
  animation: 9s  morph ease-in-out infinite alternate;
}



#circle_big_inner {
    animation: spin infinite 60s linear alternate;
    transform-origin: 805px 830px;
}

#circle_big_inner1 {
    animation: spin infinite 80s linear alternate;
    transform-origin: 805px 830px;
}

#circle_big_inner2 {
    animation: spin infinite 95s linear alternate;
    transform-origin: 805px 830px;
}

#circle_big_outer {
    animation: spin infinite 98s linear alternate;
    transform-origin: 805px 830px;
}

#circle_big_outer1 {
    animation: spin infinite 100s linear alternate;
    transform-origin: 800px 840px;
}

#circle_big_outer2 {
    animation: spin infinite 102s linear alternate;
    transform-origin: 795px 840px;
}

#circle_big_outer3 {
    animation: spin infinite 105s linear alternate;
    transform-origin: 785px 830px;
}

#circle_small_inner {
    animation: spin infinite 50s linear alternate;
    transform-origin: 2805px 1359px;
}

#circle_small_inner1 {
    animation: spin infinite 55s linear alternate; 
    transform-origin: 2805px 1359px;
}

#circle_small_outer {
    animation: spin infinite 75s linear alternate;
    transform-origin: 2805px 1359px;
}

#circle_small_outer1 {
    animation: spin infinite 80s linear alternate;
    transform-origin: 2805px 1359px;
}

@keyframes spin {
  from {transform: rotate(0deg); }
  to {
      transform: rotate(360deg);
      }
}

@keyframes morph {
  from {
      transform: rotate(-3deg); 
      transform: translateX(0%);
      transform: translateY(0.25%);
      
  }
  to {
      transform: rotate(0deg); 
      transform: translateX(8%);
      transform: translateY(2%);
      }
}

  @media (max-width: 1400px){ 
    padding: 0 4rem;
    padding-top: 0;
    #bg {
      left: -20rem;
      top: -90rem;
      width: 122%; 
    }
  }

  @media (max-width: 1300px){ 
    padding: 0 4rem;
    padding-top:0;
    #bg {
      left: -20rem;
      top: -90rem;
      width: 122%; 
    }
  }


  @media (max-width: 1200px){ 
    padding: 0 4rem;
    padding-top:0;
    #bg {
      left: -20rem;
      top: -110rem;
      width: 125%; 
    }
  }

  @media (max-width: 1100px){ 
    padding: 0 4rem;
    padding-top:0;
    #bg {
      left: -20rem;
      top: -125rem;
      width: 125%; 
    }
  }

  @media (max-width: 900px){
    padding: 0 4rem;
    #bg {
      left: -12rem;
      top: -115rem;
      width: 120%;
      height: 100%;   
    }
  

  @media (max-width: 740px){
    padding: 0 4rem;
    #bg {
      left: -10rem;
      top: -120rem;
      width: 125%;
      height: 100%;   
      padding: 0 4rem;
    }
  }

  @media(max-width: 600px){
    padding: 0 2rem;
    #bg {
      left: -8rem;
      top: -135rem;
      width: 130%;
      height: 100%;
    }
  }

  @media(max-width: 450px){
    padding: 0 2rem;
    #bg {
      left: -8rem;
      top: -165rem;
      width: 130%;
      height: 100%;
    }
  }


 @media(max-width: 375px){
    padding: 0 2rem;
    #bg {
      left: -5rem;
      top: -170rem;
      width: 130%;
      height: 100%;
    }
  }
`