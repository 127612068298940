import styled from "styled-components";


export const Container = styled.main`
    position: relative;
  z-index: 0;
  padding: 0 2rem;
  overflow-x: hidden;

.wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 10%;
    }


  h2{
    margin-top: 2.5rem;
    padding-bottom: 1rem;
}

  h3 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  h4 {
    margin-bottom: 0.5rem;
}

  p{
    letter-spacing: 0.1rem;
    font-weight: 500;
    padding-bottom: 2rem;
    padding-top: 0.5rem;
  }

.�berblick {
    padding-top: 0;
        p {
        padding-bottom: 0.5rem;
    }
}

.abstand {
    height: 20rem;
}

img {
    padding-bottom: 2rem;
}

.farben {
    width: 50%;
}
 .header{
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 8rem;
    padding-bottom: 3%;
   img{
     margin: 4rem 5rem;
     width: 90%;
   }
  }

table {
  margin-top: 4rem;
  text-align: left;
  max-width: 1100px;
    margin-left: auto;
  margin-right: auto;
  table-layout: auto;

  }

th,
td {
  padding-right: 8rem;
  padding-bottom: 6rem;
  vertical-align: top;
  img {
      width: 100%;
  }
}
td:nth-of-type(2) {
  padding-right: 0rem;
}
td:nth-of-type(1) {
  text-decoration: underline; 
  text-underline-offset: 1rem; 
  text-decoration-thickness:2px;
  text-decoration-color: var(--beige);
}


@media (max-width: 900px) {
    table, thead, tbody, th, td, tr { 
		display: block; 
        width: 100%;
	}
  
th, td { 
		/* Behave  like a "row" */
		position: relative;
        padding: 2rem 0rem;
        margin: 0rem;
	}
  
td:before { 
		/* Now like a table header */
		position: absolute;
	}
}


@media (min-width: 1600px){
        .wrapper {
         width: 80%;
         margin: 0 auto;
         padding-top: 10%;
         padding-bottom: 3%;
         }
         .header {
            gap: 6rem;
            padding-bottom: 5%;
         }
         .ueberblick {
             padding-left: 2rem;
         }
   }

  @media only screen and (max-width: 580px) {
    .header {
      margin-top: 4rem;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 3%;
      img{
        margin: 0;
        padding-top: 3rem;
        width: 100%;
            }
        }

        h2 {
            font-size: 2rem;
        }
  }

    @media only screen and (max-width: 900px) {
    .header {
            grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 3%;
      img{
        margin: 0;
        padding-top: 3rem;
        width: 70%;
            }
        }

        h2 {
            font-size: 4rem;
        }
  }

   @media (max-width: 1400px) {
       .abstand {
           height: 13rem;
       }

  @media (max-width: 1200px) {

     .header{
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 3%;
      img{
        margin: 0;
        padding-top: 3rem;
        width: 50%;
            }
        }

        h2 {
            font-size: 4rem;
        }

        .themeimage {
            text-align: center;
        }

        .abstand {
            height: 6rem;
        }

         .ueberblick {
             padding-left: 0rem;
         }
}

 @media (max-width: 700px) {

     .header{
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 3%;
      img{
        margin: 0;
        padding-top: 3rem;
        width: 100%;
            }
        }

        h2 {
            font-size: 4rem;
        }

        td {
            padding: 1rem 0;
        }
}

  }
`