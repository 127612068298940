import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"

export function Hero() {
    return (
        <Container id="home">
            <div className="hero-text">
                <ScrollAnimation animateIn="fadeInUp">
                    <div className="abstand"> <p> </p></div>
                    <h4>Portfolio</h4>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
                    <h1>Sarah Mielke</h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
                    <h3>UI/UX Design & Frontend</h3>

                    {/* 
        </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">4 Years of Experience</p>
        </ScrollAnimation>


        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <BrowserRouter>
            <NavHashLink smooth to="#contact" className="button">Kontakt</NavHashLink>
          </BrowserRouter>
        </ScrollAnimation>
        
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
      <div className="social-media"><a
        href="https://www.linkedin.com/in/codevinayak"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedin} alt="Linkedin" />
      </a> </div> */}
                </ScrollAnimation>

            </div>

            {/* 
        <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img src={Illustration} alt="Ilustração" />
        </ScrollAnimation>
      </div>
     */}

        </Container>

    )
}